import axios from 'axios'

export default axios.create({
  // baseURL: 'http://127.0.0.1:8000/api',
  // baseURL: 'https://api.app.condinvest.com.br/api',
  baseURL: 'https://api.dev.condinvest.com.br/api',

  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})
