<template>
    <div class="interface">
      <b-card class="login-card">
          <div>
              <img class="logo" src="@/assets/images/logo.jpg"/>
          </div>
          <div>
              <b-form @submit.prevent="login">

                <h2 class="title">Login</h2>

                <b-form-input
                    v-model="user.cpf"
                    type="text"
                    placeholder="CPF"
                    v-maska="['###.###.###-##', '##.###.###/####-##']"
                    required
                ></b-form-input>

                <b-form-input
                    v-model="user.password"
                    :type="eye ? 'password' : 'text'"
                    placeholder="Senha"
                    required
                    class="input-eye"
                ></b-form-input>

                <i @click="eye = !eye" class="eye material-icons-outlined">{{ eye ? 'visibility' : 'visibility_off' }}</i>

                <b-button pill variant="outline-primary" type="submit">Entrar</b-button>

                <b-button @click="forgetPasswordM" class='mt-3' pill variant="outline-secondary">Esqueci a senha</b-button>
              </b-form>
          </div>
      </b-card>
      <b-modal id="forget-password" hide-footer>
        <template #modal-title>Esqueci a senha</template>

          <b-form @submit.prevent="forgetPassword">
            <b-form-input
                v-model="fpassword.cpf"
                type="text"
                placeholder="CPF"
                v-maska="['###.###.###-##', '##.###.###/####-##']"
                required
            ></b-form-input>

            <b-form-input
                v-model="fpassword.email"
                type="email"
                placeholder="E-mail"
                required
            ></b-form-input>

            <b-button pill variant="outline-primary" type="submit">Enviar</b-button>
          </b-form>
      </b-modal>
      <b-modal id="forget-password-code" hide-footer>
        <template #modal-title>Inserir código enviado pelo email</template>

          <b-form @submit.prevent="forgetPasswordCode">

            <b-form-input
                v-model="fpassword.cod"
                type="text"
                placeholder="Código"
                required
            ></b-form-input>

            <b-button pill variant="outline-primary" type="submit">Enviar</b-button>
          </b-form>
      </b-modal>
      <b-modal id="forget-password-new" hide-footer>
        <template #modal-title>Nova Senha</template>

          <b-form @submit.prevent="forgetPasswordNew">

            <b-form-input
              v-model="fpassword.password"
              type="password"
              placeholder="Senha"
              required
            ></b-form-input>

            <b-button pill variant="outline-primary" type="submit">Enviar</b-button>
          </b-form>
      </b-modal>
  </div>
</template>

<style lang="stylus" scoped>

    @import '../style/colors.styl';
    @import '../style/fonts.styl';
    @import '../style/mixins.styl';

    .interface
        min-height 100%
        min-height 100vh
        display flex
        align-items center
        background-color primary

    .login-card
        width 90%
        min-height 81% // old support
        min-height 81vh
        margin 0 auto
        display flex

    .card-body, .card-body > div
        display flex
        justify-content space-around
        align-items center
        flex-basis 50%
        flex-grow 0

    .logo
        width 400px
        height 400px

    form
        width 100%
        position relative

        div, input, span, h2, button
            font-family OpenSans

        .title
            text-align center
            color primary-dark

        input, button
            height calc(1.5em + .75rem + 2px)
            padding .4rem 1rem

        input
            margin 25px 0
            border-radius: 50rem

        button
            width 100%

    .input-eye
        padding-right 2.5rem

    .eye
        position absolute
        right: 15px
        bottom: 119px
        font-size 32px
        color primary
        cursor pointer
        no-select()

</style>

<script>

import api from '@/services/condinvest'
import { toast } from '@/utils'

export default {
  data: () => ({
    user: {
      cpf: '',
      password: ''
    },
    fpassword: {
      cpf: '',
      email: '',
      cod: '',
      password: ''
    },
    eye: true
    // show: false, // password show/hide eye
  }),
  methods: {
    login: function () {
      api.post('login', this.user).then((response) => {
        console.log(response)
        if (response.status === 200) {
          this.$store.dispatch('login', response.data)
          this.$router.replace({ name: 'Home' })
        }
      }, error => {
        toast(this, 'danger', 'Login inválido', error.message)
        return Promise.reject(error)
      })
    },
    forgetPassword () {
      api.post('recover/request', { cpf: this.fpassword.cpf, email: this.fpassword.email }).then((response) => {
        console.log(response)
        if (response.status >= 200 && response.status < 300) {
          this.$bvModal.hide('forget-password')
          this.$bvModal.show('forget-password-code')
        }
      }, error => {
        toast(this, 'danger', 'Login inválido', error.message)
        return Promise.reject(error)
      })
    },
    forgetPasswordCode () {
      api.post('recover/confirm', { cpf: this.fpassword.cpf, email: this.fpassword.email, code: this.fpassword.cod }).then((response) => {
        console.log(response)
        if (response.status >= 200 && response.status < 300) {
          this.$store.dispatch('login', response.data)
          this.$bvModal.hide('forget-password-code')
          this.$bvModal.show('forget-password-new')
        }
      }, error => {
        toast(this, 'danger', 'Login inválido', error.message)
        return Promise.reject(error)
      })
    },
    forgetPasswordNew () {
      api.post('reset_password', { password: this.fpassword.password, password_confirmation: this.fpassword.password }).then((response) => {
        console.log(response)
        if (response.status >= 200 && response.status < 300) {
          this.$bvModal.hide('forget-password-new')
          this.$router.replace({ name: 'Home' })
        }
      }, error => {
        toast(this, 'danger', 'Login inválido', error.message)
        return Promise.reject(error)
      })
    },
    forgetPasswordM () {
      this.$bvModal.show('forget-password')
    }
  }
}
</script>
